import React from "react"
import { Badge, Button } from "reactstrap"

import Layout from "../components/layout/layout2"
import SEO from "../components/seo"
import { slugify } from "../util/utilityFunctions"

const TagsPage = ({ pageContext }) => {
  const { tags, tagPostCounts } = pageContext
  return (
    <Layout title="Tópicos">
      <SEO title="Todos os Tópicos" keywords={["tags", "tópicos"]} />
      <div className="tagsPage">
        <ul>
          {tags.map(tag => (
            <li key={tag}>
              <Button
                className="text-uppercase btn-sm"
                color="primary"
                href={`/tag/${slugify(tag)}`}
              >
                {tag}
                <Badge className="ml-2" color="light">
                  {tagPostCounts[tag]}
                </Badge>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default TagsPage
